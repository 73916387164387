import styled, { css } from 'styled-components';

export const ButtonsWrapper = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[2]};
`;

export const IconRotate = styled.div<{ isLoading: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    ${({ isLoading }) =>
        isLoading &&
        css`
            animation: rotate 1s linear infinite;
        `}

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
`;
