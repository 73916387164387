import { ReactNode, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { QUERIES, ROUTES_PANEL } from '@constants';
import { useLayout } from 'contexts/LayoutContext';
import { getAccountsDetail } from 'services';

import {
    CardAccountAccess,
    CardAccountAnalytics,
    CardAccountTags,
    CardPayments,
    HeaderForm,
} from '../components';

const AccountsForm = () => {
    const { id } = useParams();

    const [tabValue, setTabValue] = useState<string>('account-analytics');

    const titles: Record<string, string> = {
        'account-analytics': 'Análise',
        'account-tags': 'Tags',
        'account-access': 'Acesso',
        'account-payments': 'Pagamentos',
    }

    const { initLayout } = useLayout();

    useEffect(() => {
        initLayout(`Contas - ${titles[tabValue]}`, [
            {
                label: 'Agtor Admin',
                link: ROUTES_PANEL.fullPath,
            },
            {
                label: 'Contas',
                link: ROUTES_PANEL.ACCOUNTS.fullPath,
            },
            {
                label: 'Formulário',
                link: ROUTES_PANEL.ACCOUNTS.FORM.fullPath,
            },
        ]);
    }, [tabValue]);


    const { data, isLoading, refetch, isFetching } = useQuery(
        [QUERIES.ACCOUNT.module, id],
        () => getAccountsDetail(Number(id)),
        {
            enabled: Boolean(id),
        }
    );

    const tabsContent: Record<string, ReactNode> = {
        'account-analytics': <CardAccountAnalytics data={data} isLoading={isLoading} />,
        'account-tags': (
            <CardAccountTags
                data={{ id: data?.id || 0, accountsTags: data?.accountsTags || [] }}
            />
        ),
        'account-access': (
            <CardAccountAccess data={data?.usersAccess || []} />
        ),
        'account-payments': (
            <CardPayments payments={data?.subscriptionsPayments || []} />
        )
    };

    const getCurrentSubscription = () => {
        if (!data?.subscriptions) return undefined

        return data?.subscriptions?.[data.subscriptions.length - 1]
    }

    return (
        <div>
            <HeaderForm
                hasIntegration={Boolean(data?.gatewayPayments?.[0]?.integrationId)}
                email={data?.usersAccess?.map(({ users }: any) => users?.email).join(', ')}
                name={data?.name}
                login={data?.usersAccess?.map(({ users }: any) => users?.login).join(', ')}
                phone={data?.usersAccess?.map(({ users }: any) => users?.phone).join(', ')}
                counts={data?.counts}
                tabValue={tabValue}
                setTabValue={setTabValue}
                createdAt={data?.createdAt}
                updatedAt={data?.updatedAt}
                subscriptionPlan={getCurrentSubscription()}
                isFetching={isFetching}
                onRefetch={() => refetch()}
            />

            {tabsContent[tabValue]}
        </div>
    );
};

export default AccountsForm;
