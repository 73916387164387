import { FormEvent, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { QUERIES } from '@constants';
import { Button, Card, Drawer, InputText, Select } from 'components';
import { onValidationSchema, onErrorMessage, onSuccessMessage } from 'helpers';
import {
    useCreateAssistantAgent,
    useGetAssistantAgentDetail,
    useUpdateAssistantAgent
} from 'services';


import { AssistantAgentFormPromptField } from './components'

import { ButtonsWrap, FormWrap } from './styles'
import type { IAAssistantAgent, IAAssistantAgentPrompt } from '@types';
import type { AssistantAgentFormInterface } from './interface';

export const AssistantAgentForm = ({
    id,
    isOpen,
    onOpen,
}: AssistantAgentFormInterface) => {
    const queryClient = useQueryClient();

    const titleMessages = 'Agentes de Assistente';

    const [slug, setSlug] = useState('');
    const [active, setActive] = useState(true);
    const [identify, setIdentify] = useState('');
    const [prompt, setPromp] = useState<Partial<IAAssistantAgentPrompt>[]>([]);

    const [isOpenDrawerPrompt, setIsOpenDrawerPrompt] = useState(false);


    const { data: dataGet, isLoading: isLoadingGet } = useGetAssistantAgentDetail(id)

    const { mutateAsync: createAssistantAgentAsync, isLoading: isLoadingCreate } = useCreateAssistantAgent();

    const { mutateAsync: updateAssistantAgentAsync, isLoading: isLoadingUpdate } = useUpdateAssistantAgent(id);

    const onHandleClose = () => {
        onOpen(false);
        setIsOpenDrawerPrompt(false);
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const data: Partial<IAAssistantAgent> = {
            active,
            slug,
            identify,
            prompt: prompt?.map((item) => ({ content: item.content })) as IAAssistantAgentPrompt[] || [],
        };

        const shapeSchema = {
            active: Yup.boolean().nullable().required('O status é obrigatório.'),
            slug: Yup.string().nullable().required('O slug é obrigatório.'),
            prompt: Yup.array().nullable().required('O prompt é obrigatório.'),
        };

        const { success } = await onValidationSchema(shapeSchema, data);

        if (success) {
            let response: {
                success: boolean
                message: string
                data?: IAAssistantAgent
            } = {
                success: false,
                message: '',
            }

            if (!id) {
                response = await createAssistantAgentAsync(data);
            } else {
                response = await updateAssistantAgentAsync(data);
            }

            if (response?.success) {
                onSuccessMessage(
                    titleMessages,
                    id ? 'Sucesso ao atualizar o registro!' : 'Sucesso ao criar novo registro!'
                );

                queryClient.refetchQueries([QUERIES.ASSISTANT_AGENT.module]);
                onOpen(false);
            } else {
                onErrorMessage(titleMessages, 'Erro ao salvar o registro.');
            }
        }
    };


    useEffect(() => {
        if (id) {
            if (dataGet?.data) {
                setSlug(dataGet?.data?.slug);
                setActive(dataGet?.data?.active);
                setIdentify(dataGet?.data?.identify || '')
                setPromp(dataGet?.data?.prompt || []);
            }
        } else {
            setSlug('');
            setIdentify('')
            setActive(true);
            setPromp([]);
        }
    }, [dataGet, id])

    const isLoading = isLoadingGet || isLoadingCreate || isLoadingUpdate;

    return (
        <form onSubmit={onSubmit}>
            <Drawer
                isOpen={isOpen}
                onOpen={onHandleClose}
            >
                <Card
                    style={{ height: '100%' }}
                    styleContent={{ overflowY: 'auto', padding: 0 }}
                    headerTitle="Agentes AI"
                    headerSubTitle={id ? 'Editar Registro' : 'Novo Registro'}
                    footer={
                        <ButtonsWrap>
                            <Button
                                type="button"
                                model="light"
                                onClick={onHandleClose}
                            >
                                Fechar
                            </Button>
                            <Button
                                type="submit"
                                isDisabled={isLoading}
                                isLoading={isLoading}
                            >
                                Salvar Agente
                            </Button>
                        </ButtonsWrap>
                    }
                >
                    <FormWrap>
                        <InputText
                            isRequired
                            value={slug}
                            onChange={setSlug}
                            label="Slug"
                            name="slug"
                            placeholder="Informe um slug"
                            isLoading={isLoading}
                        />


                        <Select
                            isRequired
                            label="Status"
                            name="status"
                            placeholder="Selecione o status"
                            value={active ? 'true' : 'false'}
                            onChange={(newValue) => setActive(newValue === 'true')}
                            isLoading={isLoading}
                            options={[
                                { value: 'true', label: 'Ativo' },
                                { value: 'false', label: 'Inativo' },
                            ]}
                        />

                        <InputText
                            isRequired
                            value={identify}
                            onChange={setIdentify}
                            label="Identificação"
                            name="identify"
                            placeholder="Informe uma frase para identificar esse fluxo"
                            isLoading={isLoading}
                        />


                        <AssistantAgentFormPromptField 
                            isOpen={isOpenDrawerPrompt}
                            onOpen={setIsOpenDrawerPrompt}
                            items={prompt}
                            isLoading={isLoading}
                            onSave={setPromp}
                        />


                    </FormWrap>
                </Card>
            </Drawer>
        </form>
    );
};